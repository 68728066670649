<template>
  <div class="dropDown" :style="dropStyle">
    <input type="text" v-model="dropId" />
    <span @click="open">{{ dropValue }}</span>
    <ul :class="{ active: isOpen }">
      <li data-id="0" @click="setData">Выбрать</li>
      <li
        v-for="(item, key) in list"
        :data-id="item.id"
        :data-bgColor="item.bg"
        :data-textColor="item.text"
        :key="key"
        @click="setData"
      >
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
.dropDown {
  span {
    color: inherit !important;
  }
  ul {
    z-index: 99999 !important;
    li {
      color: #454545 !important;
    }
  }
}
</style>
<script>
export default {
  props: ["list", "selected", "itemId"],
  data() {
    return {
      isOpen: false,
      dropValue: "Выбрать",
      dropId: this.$props.selected,
      dropBgColor: "",
      dropTextColor: "",
      dropStyle: {},
    };
  },
  methods: {
    renderSelected() {
      if (this.$props.selected != 0 && this.$props.selected != undefined) {
        this.dropId = this.$props.selected;
        this.$props.list.forEach((element) => {
          if (this.dropId == element.id) {
            this.dropValue = element.name;
            if (element.bg) {
              this.dropStyle = {
                backgroundColor: element.bg,
                color: element.text,
              };
            }
          }
        });
        if (this.dropId == 0) {
            this.dropValue = "Выбрать";
            
          }
      } else {
        this.dropId = 0
        this.dropValue = "Выбрать"
      }
    },
    open() {
      this.isOpen = !this.isOpen;
    },
    setData(e) {
      let elem = e.target;
      this.dropValue = elem.innerText;
      this.dropId = elem.dataset.id;
      if(this.dropId==111) {
        if(!confirm('Будет отправлено SMS? подтверждаете?')) {
          return
        }
      }
      this.isOpen = false;
      if (elem.dataset.bgcolor) {
        this.dropStyle = {
          backgroundColor: elem.dataset.bgcolor,
          color: elem.dataset.textcolor,
        };
      }
      console.log(this.dropStyle);
      this.$emit("dropSelect", {
        value: this.dropValue,
        id: this.dropId,
        itemId: this.$props.itemId,
      });
    },
  },
  watch: {
    selected: function () {
      this.renderSelected()
      
    },
  },
  mounted() {
    console.log(this.dropId, this.$props.list);
    this.renderSelected();
    const F = (e) => {
      if (e.target.closest(".dropDown") == null) {
        this.isOpen = false;
      }
    };
    document.addEventListener("click", F);
  },
};
</script>
