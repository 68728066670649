<template>
  <header class="flex">
    <h1>{{ pageName }}</h1>
    <div class="action flex">
      <span>{{ adminName }}</span>
      <a href="#!" @click.prevent="exit">Выход</a>
    </div>
  </header>
</template>
<style lang="scss" >
  .wrapper .content {
    flex: 5;
  }
  .userComment {
    overflow: hidden;
    height: 32px;
    display: flex;
    justify-content: flex-start;
  }
  .managerComment {
    position: relative;
    widows: 20px;
    height: 20px;
    transition: 0.3s ease-out;
    background: url(/img/ico-manager-comment.svg) no-repeat center center;
    background-size: 20px;
    overflow: hidden;
    
    cursor: pointer;
    b {
      top: -10px;
      opacity: 0;
      width: 220px;
      display: block;
      z-index: 99999;
      left: -20px;
      position: absolute;
      transition: 0.3s ease-out;
      font-size: 12px;
      font-weight: 500;
      padding: 5px;
      color: white;
      background: #454545;
      border-radius: 3px;
    }
    &:hover {
      overflow: visible;
      b {
      top: -5px;
      opacity: 1;
      }
    }
  }
</style>
<script>
import helper from "@/components/app/helper";
import Config from "@/components/app/config";
export default {
  data() {
    return {
      isProtect: false,
      adminName: "",
      pageName: "",
      adminId: "0",
      pageList: {},
    };
  },
  methods: {
    exit() {
      helper.methods.setCookie("token", "");  
      this.$router.push("/");
    },
    protect() {
      let token = helper.methods.getCookie("token");
      if (token == "" || token == undefined) {
      //  this.$router.push("/");
      }
      this.adminName = helper.methods.getCookie("name");
      if (this.$route.name in this.pageList) {
        document.title = this.pageList[this.$route.name];
        this.pageName = this.pageList[this.$route.name];
      }
    },
  },
  mounted() {
    this.pageList = Config.pageList;
    this.protect();
  },
};
</script>
