<script>
export default {
        filterTypeList: [
            {name: "Значение", id: 1},
            {name: "Диапазон", id: 2}
        ],
        statusList: [
            {name: "Новый заказ", id: 1, bg:"#ffffff", text: "#454545"},           
            {name: "Заказа в пути", id: 20, bg:"#e1295d", text: "#ffd9e4"},
            {name: "На складе в РФ", id: 2, bg:"#63ff93", text: "#02601f"},
            {name: "На складе у нас", id: 21, bg:"#047eb1", text: "#b4e2f5"},
            {name: "Выдан", id: 4, bg:"#02601f", text: "#ffffff"},
           
        ],
        statusForProducts: [
            {name: "В обработке", id: 0, bg:"#ffffff", text: "#454545"},
            {name: "Нет в наличии", id: 1, bg:"#ffd0d8", text: "#536258"},
            {name: "Отменен клиентом", id: 2, bg:"#ff33d8", text: "#fee1ca"}
        ],
        pageList: {
            admins: "Администраторы",
            orders: "Заказы",
            sections: "Категории",
            filters: "Фильтры",
            salers: "Наценки",
            banners: "Баннеры",
            products: "Товары",
            order: "Заказ",
            ordersPage: "Заказы"
        }        
 }
  
</script>
